<template>
  <grid stackable doubling>
    <grid-column>
      <h2>Information</h2>
      <g-table basic compact fixed>
        <table-body>
          <table-row>
            <table-cell> Preferred First Name </table-cell>
            <table-cell>
              <editable-text
                :value="user.preferredFirstName"
                placeholder="Preferred First Name"
                :loading="patchingUserField === 'preferredFirstName'"
                @update="(value) => patchUser('preferredFirstName', value)"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
          <table-row>
            <table-cell> Legal First Name </table-cell>
            <table-cell>
              <editable-text
                :value="user.legalFirstName"
                placeholder="Legal First Name"
                :loading="patchingUserField === 'legalFirstName'"
                @update="(value) => patchUser('legalFirstName', value)"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
          <table-row>
            <table-cell> Last Name </table-cell>
            <table-cell>
              <editable-text
                :value="user.lastName"
                placeholder="Last Name"
                :loading="patchingUserField === 'lastName'"
                @update="(value) => patchUser('lastName', value)"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
          <table-row>
            <table-cell> Work Email </table-cell>
            <table-cell>
              <!-- our backend requires emails to be lowercased -->
              <editable-text
                :value="user.email"
                placeholder="Work Email"
                :loading="patchingUserField === 'email'"
                @update="(value) => patchUser('email', toLower(value) || null)"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
          <table-row>
            <table-cell> Personal Email </table-cell>
            <table-cell>
              <!-- our backend requires emails to be lowercased -->
              <editable-text
                :value="user.personalEmail"
                placeholder="Personal Email"
                :loading="patchingUserField === 'personalEmail'"
                @update="
                  (value) => patchUser('personalEmail', toLower(value) || null)
                "
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
          <table-row>
            <table-cell> Birthday </table-cell>
            <table-cell>
              <date-picker
                dropdown
                :date="parseISO(user.birthday)"
                :loading="patchingUserField === 'birthday'"
                @update="
                  (date) =>
                    patchUser(
                      'birthday',
                      formatISO(date, { representation: 'date' })
                    )
                "
              />
            </table-cell>
            <table-cell right-aligned>
              <btn
                v-if="user.birthday"
                dismiss
                tooltip="Clear Birthday"
                :loading="patchingUserField === 'birthday'"
                compact
                size="mini"
                @click.native="() => patchUser('birthday', null)"
              />
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Start Date </table-cell>
            <table-cell>
              <date-picker
                dropdown
                :date="parseISO(user.startDate)"
                :loading="patchingUserField === 'startDate'"
                @update="
                  (date) =>
                    patchUser(
                      'startDate',
                      formatISO(date, { representation: 'date' })
                    )
                "
              />
            </table-cell>
            <table-cell right-aligned>
              <btn
                v-if="user.startDate"
                dismiss
                tooltip="Clear Start Date"
                :loading="patchingUserField === 'startDate'"
                compact
                size="mini"
                @click.native="() => patchUser('startDate', null)"
              />
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> End Date </table-cell>
            <table-cell>
              <date-picker
                dropdown
                :date="parseISO(user.endDate)"
                :loading="patchingUserField === 'endDate'"
                @update="
                  (date) =>
                    patchUser(
                      'endDate',
                      formatISO(date, { representation: 'date' })
                    )
                "
              />
            </table-cell>
            <table-cell right-aligned>
              <btn
                v-if="user.endDate"
                dismiss
                tooltip="Clear End Date"
                :loading="patchingUserField === 'endDate'"
                compact
                size="mini"
                @click.native="() => patchUser('endDate', null)"
              />
            </table-cell>
          </table-row>
          <table-row>
            <table-cell> Admin Role </table-cell>
            <table-cell>
              <dropdown
                name="Gather Role"
                placeholder="Choose Permission"
                :value="user.role"
                :loading="patchingUserField === 'role'"
                :disabled="isRoleDisabled"
                :options="gatherRoles"
                reduced-field="value"
                label="name"
                @update="setRole"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
        </table-body>
      </g-table>
    </grid-column>
    <confirm-modal
      ref="patchRole"
      approve-text="Change"
      deny-text="Cancel"
      size="tiny"
      @approve="patchRole"
      @deny="() => (newRole = null)"
    >
      Are you sure you want to change {{ user && user.fullName }}'s role to
      {{ capitalize(newRole) }}?
      {{ newRole === 'employee' ? ' They will lose access to Gather.' : '' }}
    </confirm-modal>
  </grid>
</template>

<script>
import { parseISO, formatISO } from 'date-fns'
import { capitalize, toLower } from 'lodash'

import { api } from '@/api'
import { toast } from '@/toasts'

import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import datePicker from '@/components/v2/date_picker.vue'
import editableText from '@/components/v2/editable_text.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import btn from '@/components/v2/btn.vue'
import dropdown from '@/components/v3/dropdown.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'

export default {
  components: {
    gTable,
    tableCell,
    tableBody,
    tableRow,
    datePicker,
    editableText,
    grid,
    gridColumn,
    btn,
    dropdown,
    confirmModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parseISO,
      formatISO,
      toLower,
      capitalize,
      patchingUserField: null,
      gatherRoles: [
        { name: 'Admin', value: 'admin' },
        { name: 'Employee', value: 'employee' },
      ],
      newRole: null,
    }
  },
  computed: {
    isRoleDisabled() {
      return (
        (this.user && this.user.id) ===
          (this.$store.state.currentUser && this.$store.state.currentUser.id) ||
        !['active', 'onboarding'].includes(this.user.status)
      )
    },
  },
  methods: {
    async patchUser(field, value) {
      try {
        this.patchingUserField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.user.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingUserField = null
      }

      this.$emit('get-user')
    },
    async patchRole() {
      await this.patchUser('role', this.newRole)
      this.newRole = null
    },
    setRole(role) {
      this.newRole = role
      this.$refs.patchRole.show()
    },
  },
}
</script>
