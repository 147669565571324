<template>
  <list-content>
    <flex align="center" justify="space-between">
      <dropdown
        name="Edit Role"
        default-text="Select Person"
        class="role-dropdown"
        scrolling
        floating
        :initial-value="usersRelationship[field]"
        :loading="patchingUsersRelationship"
        @update="(value) => patchUsersRelationship(usersRelationship.id, value)"
      >
        <dropdown-search />
        <dropdown-item v-for="user_ in users" :key="user_.id" :value="user_.id">
          <g-image
            avatar
            :src="user_.avatar || defaultUserAvatar"
            alt="Profile picture"
          />
          {{ user_.fullName }}
        </dropdown-item>
      </dropdown>

      <btn
        dismiss
        tooltip="Remove"
        compact
        size="mini"
        :loading="deletingUsersRelationship"
        @click.native.prevent="
          () => deleteUsersRelationship(usersRelationship.id)
        "
      />
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import gImage from '@/components/v2/image.vue'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

export default {
  components: {
    listContent,
    dropdown,
    dropdownItem,
    dropdownSearch,
    flex,
    btn,
    gImage,
  },
  props: {
    usersRelationship: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['superior', 'subordinate'].includes(value),
    },
  },
  data() {
    return {
      defaultUserAvatar,
      deletingUsersRelationship: false,
      patchingUsersRelationship: false,
    }
  },
  computed: {
    field() {
      if (this.type === 'superior') {
        return 'firstUserId'
      }
      if (this.type === 'subordinate') {
        return 'secondUserId'
      }

      throw new Error(`Unknown type ${this.type} for relationship`)
    },
  },
  methods: {
    async patchUsersRelationship(usersRelationshipId, value) {
      try {
        this.patchingUsersRelationship = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships/${usersRelationshipId}`,
          { [this.field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingUsersRelationship = false
      }
      this.$emit('get-users-relationships')
    },
    async deleteUsersRelationship(usersRelationshipId) {
      try {
        this.deletingUsersRelationship = true
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships/${usersRelationshipId}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingUsersRelationship = false
      }

      this.$emit('get-users-relationships')
    },
  },
}
</script>

<style lang="less" scoped>
.role-dropdown {
  margin: 1rem 0;
}
</style>
