<template>
  <grid stackable doubling>
    <grid-column>
      <flex justify="space-between" align="flex-start">
        <h2>Groups</h2>
        <dropdown
          name="groups"
          button-variant="primary"
          basic
          compact
          button
          default-text="Add"
          hide-caret
          scrolling
          hide-selection
          :loading="addingUser"
          :disabled="groups.length === 0"
          @update="(value) => addUserToGroup(value)"
          @search="(value) => $emit('get-groups', value)"
        >
          <dropdown-search />
          <dropdown-item
            v-for="group in groups"
            :key="group.id"
            :value="group.id"
          >
            {{ group.name }}
          </dropdown-item>
        </dropdown>
      </flex>
      <list middle-aligned selection relaxed>
        <list-item
          v-for="group in memberGroups"
          :key="group.id"
          :to="{ name: 'group', params: { id: group.id } }"
        >
          <profile-group
            :group="group"
            :user="user"
            @get-groups="() => $emit('get-groups')"
          />
        </list-item>

        <placeholder v-if="memberGroups.length === 0" light>
          {{ user.fullName }} is not a part of any groups right now.
        </placeholder>
      </list>
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import placeholder from '@/components/v2/placeholder.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import flex from '@/components/v2/flex.vue'

import profileGroup from '@/views/v2/profile/profile_group.vue'

export default {
  components: {
    grid,
    gridColumn,
    list,
    listItem,
    placeholder,
    dropdown,
    dropdownSearch,
    dropdownItem,
    flex,
    profileGroup,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    memberGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addingUser: false,
    }
  },
  methods: {
    async addUserToGroup(groupId) {
      try {
        this.addingUser = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${groupId}/members`,
          {
            userId: this.user.id,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.addingUser = false
      }
      this.$emit('get-groups')
      this.$emit('get-groups-with-member')
    },
  },
}
</script>
