<template>
  <list-content>
    <flex align="center" justify="space-between">
      <flex align="center" gap="1rem">
        <moment-icon
          :hex-color="moment.color"
          :icon="moment.icon"
          size="medium"
        />
        {{ moment.name }}
        <g-label v-if="moment.isActive" size="mini" green class="active-label">
          Active
        </g-label>
      </flex>
      <btn
        dismiss
        tooltip="Unenroll"
        :loading="deletingUserMoment"
        compact
        size="mini"
        @click.native.prevent="() => deleteUserMoment()"
      />
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import gLabel from '@/components/v2/label.vue'

export default {
  components: {
    listContent,
    flex,
    btn,
    momentIcon,
    gLabel,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    moment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletingUserMoment: false,
    }
  },
  methods: {
    async deleteUserMoment() {
      try {
        this.deletingUserMoment = true
        const [edge] = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals`,
            { userId: this.user.id }
          )
        ).data
        if (edge) {
          await api.delete(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${this.moment.id}/enrollees/individuals/${edge.id}`
          )
        } else {
          toast.info(
            `${this.user.fullName} cannot be unenrolled because they've been enrolled through a group`
          )
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingUserMoment = false
      }
      this.$emit('get-moments')
    },
  },
}
</script>

<style lang="less" scoped>
.active-label {
  margin-right: 1rem !important;
}
</style>
