<template>
  <loading v-if="pageLoading" :loading="pageLoading" />
  <container v-else>
    <page-header>
      <grid v-if="user">
        <grid-column thirteen>
          <flex align="center">
            <g-image
              circular
              size="tiny"
              :src="user.avatar || defaultUserAvatar"
              alt="Profile picture"
            />
            <h1 class="full-name">
              <editable-text
                class="name"
                placeholder="First Name"
                :value="user.firstName"
                :loading="patchingUserField === 'preferredFirstName'"
                @update="(value) => patchUser('preferredFirstName', value)"
              />
              <editable-text
                class="name"
                placeholder="Last Name"
                :value="user.lastName"
                :loading="patchingUserField === 'lastName'"
                @update="(value) => patchUser('lastName', value)"
              />
            </h1>
            <g-label v-bind="{ [statusColor]: true }">
              {{ status }}
            </g-label>
            <g-label
              v-if="!user.slackUserId"
              v-tippy
              red
              content="This person's email does not match any person found in Slack."
            >
              NOT CONNECTED TO SLACK
            </g-label>
            <g-label
              v-if="finchIntegration && !user.finchUserId"
              v-tippy
              red
              :content="`This person's unique HR ID could not be found in
              ${
                finchIntegration.displayName || 'the system'
              }. They may not be a full-time employee or may have an incorrect work email.
              `"
            >
              NOT CONNECTED TO
              {{ toUpper(finchIntegration.displayName) || 'HR SYSTEM' }}
            </g-label>
          </flex>
        </grid-column>
        <grid-column three right-aligned middle-aligned>
          <btn
            v-if="showHeaderActions"
            variant="secondary"
            icon="fas fa-user-minus"
            class="btn"
            :tooltip="`Dismiss ${user.firstName}`"
            :loading="dismissingUser"
            @click.native="$refs.dismiss.show"
          />
          <btn
            v-if="showHeaderActions"
            variant="secondary"
            icon="fas fa-trash"
            class="btn"
            :tooltip="`Delete ${user.firstName}`"
            :loading="archivingUser"
            @click.native="$refs.delete.show"
          />
        </grid-column>
      </grid>
    </page-header>
    <grid stackable container doubling class="profile-body">
      <grid-column twelve>
        <profile-information v-if="user" :user="user" @get-user="getUser" />
        <custom-fields
          v-if="user && (aspects.length || userAspects.length)"
          :user="user"
          :user-aspects="userAspects"
          :aspects="aspects"
          @get-user="getUser"
          @get-user-aspects="getUserAspects"
          @get-aspects="getAspects"
        />
      </grid-column>
      <grid-column six>
        <profile-moments
          v-if="user"
          :user="user"
          :moments="moments"
          :enrolled-moments="enrolledMoments"
          @get-moments="getMoments"
        />
      </grid-column>
      <grid-column six>
        <profile-groups
          v-if="user"
          :user="user"
          :groups="groups"
          :member-groups="groupsWithMember"
          @get-groups="getGroups"
        />
      </grid-column>
      <grid-column twelve>
        <template v-if="user">
          <h2>Relationships</h2>
          <template v-for="relationship in relationships">
            <grid :key="relationship.id" stackable>
              <grid-column eight>
                <profile-relationship
                  :key="`${relationship.id}-superior`"
                  type="superior"
                  :relationship="relationship"
                  :users="users"
                  :user="user"
                  @get-users="getUsers"
                />
              </grid-column>
              <grid-column eight>
                <profile-relationship
                  :key="`${relationship.id}-subordinate`"
                  type="subordinate"
                  :relationship="relationship"
                  :users="users"
                  :user="user"
                  @get-users="getUsers"
                />
              </grid-column>
            </grid>
          </template>
          <placeholder v-if="relationships.length === 0" light>
            {{ user.fullName }} doesn't have any relationships
          </placeholder>
        </template>
      </grid-column>
    </grid>
    <confirm-modal
      ref="dismiss"
      negative
      approve-text="Dismiss"
      deny-text="Cancel"
      size="mini"
      @approve="dismissUser"
    >
      Are you sure you want to dismiss {{ user && user.fullName }}?
    </confirm-modal>
    <confirm-modal
      ref="delete"
      negative
      approve-text="Delete"
      deny-text="Cancel"
      size="mini"
      @approve="archiveUser"
    >
      Are you sure you want to delete {{ user && user.fullName }}?
    </confirm-modal>
  </container>
</template>

<script>
import { formatISO } from 'date-fns'
import { toUpper } from 'lodash'
import { api } from '@/api'
import { toast } from '@/toasts'

import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import btn from '@/components/v2/btn.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import gLabel from '@/components/v2/label.vue'
import gImage from '@/components/v2/image.vue'
import flex from '@/components/v2/flex.vue'
import editableText from '@/components/v2/editable_text.vue'
import placeholder from '@/components/v2/placeholder.vue'

import defaultUserAvatar from '@/assets/img/profile_avatar_large.png'

import dateMixin from '@/mixins/v2/dateMixin'

import customFields from '@/views/v2/profile/custom_fields.vue'
import profileInformation from '@/views/v2/profile/profile_information.vue'
import profileMoments from '@/views/v2/profile/profile_moments.vue'
import profileGroups from '@/views/v2/profile/profile_groups.vue'
import profileRelationship from '@/views/v2/profile/profile_relationship.vue'

export default {
  components: {
    loading,
    container,
    pageHeader,
    customFields,
    grid,
    gridColumn,
    btn,
    confirmModal,
    gLabel,
    gImage,
    flex,
    editableText,
    profileInformation,
    profileMoments,
    profileGroups,
    profileRelationship,
    placeholder,
  },
  mixins: [dateMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      defaultUserAvatar,
      userLoading: false,
      usersLoading: false,
      userAspectsLoading: false,
      momentsLoading: false,
      groupsLoading: false,
      relationshipsLoading: false,
      aspectsLoading: false,
      user: null,
      users: [],
      groups: [],
      relationships: [],
      moments: [],
      enrolledMoments: [],
      aspects: [],
      userAspects: [],
      groupsWithMember: [],
      patchingUserField: null,
      archivingUser: false,
      dismissingUser: false,
      finchIntegration: null,
      toUpper,
    }
  },
  computed: {
    statusColor() {
      if (this.user === null) {
        return this.user
      }

      if (this.user.status === 'onboarding') {
        return 'light-blue'
      }

      if (this.user.status === 'inactive') {
        return 'light-orange'
      }

      if (this.user.status === 'active') {
        return 'green'
      }

      return ''
    },
    status() {
      if (this.user === null) {
        return this.user
      }

      if (this.user.status === 'onboarding') {
        return 'ONBOARDING'
      }

      if (this.user.status === 'inactive') {
        return 'ALUMNI'
      }

      if (this.user.status === 'active') {
        return 'ACTIVE'
      }

      return ''
    },
    showHeaderActions() {
      return parseInt(this.id, 10) !== this.$store.state.currentUser.id
    },
    pageLoading() {
      return (
        this.userLoading &&
        this.usersLoading &&
        this.aspectsLoading &&
        this.groupsLoading &&
        this.relationshipsLoading &&
        this.momentsLoading &&
        this.userAspectsLoading
      )
    },
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (oldValue && oldValue.status === newValue.status) {
          return
        }
        if (
          oldValue &&
          oldValue.status !== 'inactive' &&
          newValue.status === 'inactive'
        ) {
          this.resetPage()
          this.getUserProfile()
        }
        if (
          oldValue &&
          oldValue.status === 'inactive' &&
          newValue.status !== 'inactive'
        ) {
          this.getUserProfile()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getUserProfile()
  },
  methods: {
    resetPage() {
      this.groups = []
      this.relationships = []
      this.moments = []
      this.enrolledMoments = []
      this.groupsWithMember = []
    },
    async getUserProfile() {
      try {
        await this.getUser()
        await this.getUsers()
        await this.getAspects()
        await this.getUserAspects()
        await this.getFinchIntegration()
        if (this.user.status !== 'inactive') {
          await this.getGroups()
          await this.getRelationships()
          await this.getMoments()
        }
      } catch (error) {
        toast.error(error)
      }
    },
    async getFinchIntegration() {
      try {
        this.finchIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/info`
          )
        ).data
      } catch (error) {
        if (error.code !== 404) {
          toast.error(error)
        }
        this.finchIntegration = null
      }
    },
    async getUser() {
      try {
        this.userLoading = true
        this.user = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.id}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.userLoading = false
      }
    },
    async getUsers(search) {
      let response
      this.usersLoading = true
      while (!response || response.cursor.nextPage) {
        try {
          // eslint-disable-next-line no-await-in-loop
          response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt((response && response.cursor.nextPage) || 1, 10),
              ...(search ? { search } : {}),
              status: ['active', 'onboarding'],
              sort: 'preferredFirstName,lastName,id',
            }
          )
          this.users = this.users.concat(response.data)
        } catch (error) {
          toast.error(error)
          break
        }
      }
      this.usersLoading = false
    },
    async getAspects() {
      try {
        this.aspectsLoading = true
        this.aspects = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/aspects`, {
            sort: 'isEditable,id',
            excludeUser: this.id,
            isEditable: 1,
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.aspectsLoading = false
      }
    },
    async getGroups(search) {
      try {
        this.groupsLoading = true
        this.groups = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`, {
            sort: 'name,id',
            excludeUser: this.id,
            ...(search ? { search } : {}),
          })
        ).data
        this.groupsWithMember = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`, {
            sort: 'isSmartGroup-desc,name,id',
            memberId: this.id,
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.groupsLoading = false
      }
    },
    async getRelationships() {
      try {
        this.relationshipsLoading = true
        this.relationships = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/relationships`, {
            sort: 'name',
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.relationshipsLoading = false
      }
    },
    async getMoments(search) {
      try {
        this.momentsLoading = true
        this.enrolledMoments = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`, {
            sort: 'isActive-desc,name',
            userId: this.id,
          })
        ).data
        this.moments = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`, {
            sort: 'name',
            excludeUser: this.id,
            ...(search ? { search } : {}),
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.momentsLoading = false
      }
    },
    async getUserAspects() {
      try {
        this.userAspectsLoading = true
        this.userAspects = (
          await api.get(`${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`, {
            userId: this.id,
            isEditable: 1,
          })
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.userAspectsLoading = false
      }
    },
    async patchUser(field, value) {
      try {
        this.patchingUserField = field
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.id}`,
          { [field]: value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingUserField = null
      }
      await this.getUser()
    },

    async archiveUser() {
      try {
        this.archivingUser = true
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.id}`,
          {
            isArchived: true,
            status: 'deleted',
          }
        )
        this.$router.push({ name: 'people' })
        toast.success(`${this.user.fullName} has been successfully deleted`)
      } catch (error) {
        toast.error(error)
      } finally {
        this.archivingUser = false
      }
    },
    async dismissUser() {
      try {
        this.dismissingUser = true
        const now = new Date()

        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users/${this.id}`,
          { endDate: formatISO(now, { representation: 'date' }) }
        )

        toast.success(
          `${this.user.fullName} has an end date of ${this.formatDate(
            now,
            true
          )}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.dismissingUser = false
      }

      await this.getUser()
      await this.getUserAspects()
    },
  },
}
</script>

<style lang="less" scoped>
.profile-body {
  padding-bottom: 3em;
}

.btn {
  margin-left: 0.5em;
}

.full-name {
  display: inherit;
  margin: 0 0.5em !important;

  .name ::v-deep .editable-text {
    padding-right: 0.15em;
    padding-left: 0.15em;
    margin: 0;
  }

  .name ::v-deep .editable-text:focus {
    min-width: 2.5em;
  }
}
</style>
