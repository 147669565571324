<template>
  <list-content>
    <flex align="center" justify="space-between">
      {{ group.name }}
      <flex
        v-if="group.isSmartGroup"
        v-tippy
        align="center"
        justify="center"
        class="smart-group"
        content="Smart Group"
      >
        <span class="emoji">⚡️</span>
      </flex>
      <btn
        v-else
        dismiss
        tooltip="Remove"
        :loading="deletingGroup"
        compact
        size="mini"
        @click.native.prevent="() => removeUserFromGroup(group.id)"
      />
    </flex>
  </list-content>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import listContent from '@/components/v2/list/content.vue'
import flex from '@/components/v2/flex.vue'
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    listContent,
    flex,
    btn,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deletingGroup: false,
    }
  },
  methods: {
    async removeUserFromGroup(groupId) {
      try {
        this.deletingGroup = true
        const [edge] = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${groupId}/members/`,
            {
              userId: this.user.id,
            }
          )
        ).data
        if (edge) {
          await api.delete(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups/${groupId}/members/${edge.id}`
          )
        } else {
          throw new Error('This member does not exist')
        }
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingGroup = false
      }
      this.$emit('get-groups')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/borders.less';

.smart-group {
  width: 1.75em;
  height: 1.75em;
  margin-right: 0.25em;
  background-color: @light-green;
  border-radius: @standard-border-radius;

  .emoji {
    // HACK to center emoji on retina screens due to chrom bug
    font-size: 4em;
    transform: scale(0.25);
  }
}
</style>
