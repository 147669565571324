<template>
  <loading v-if="relationshipLoading" :loading="relationshipLoading" />
  <grid v-else>
    <grid-column sixteen>
      <h4>
        {{ usersRelationships.length > 1 ? pluralize(roleName) : roleName }}
      </h4>
      <list middle-aligned divided>
        <list-item
          v-for="usersRelationship in usersRelationships"
          :key="usersRelationship.id"
        >
          <profile-users-relationship
            :users-relationship="usersRelationship"
            :users="users"
            :type="type"
            :user="user"
            @get-users="(value) => $emit('get-users', value)"
            @get-users-relationships="getUsersRelationships"
          />
        </list-item>
        <placeholder v-if="usersRelationships.length === 0" light>
          {{ user.fullName }} doesn't have any {{ pluralize(roleName) }}
        </placeholder>
      </list>
      <flex justify="space-between">
        <dropdown
          ref="role"
          name="Role"
          :default-text="`Add ${roleName || 'Person'}`"
          fluid
          selection
          search
          @update="createUsersRelationship"
        >
          <dropdown-item
            v-for="user_ in users"
            :key="user_.id"
            :value="user_.id"
          >
            <g-image
              avatar
              :src="user_.avatar || defaultUserAvatar"
              alt="Profile Picture"
            />
            {{ user_.fullName }}
          </dropdown-item>
        </dropdown>
      </flex>
    </grid-column>
  </grid>
</template>

<script>
import pluralize from 'pluralize'

import { api } from '@/api'
import { toast } from '@/toasts'

import loading from '@/components/v2/loading.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import placeholder from '@/components/v2/placeholder.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import flex from '@/components/v2/flex.vue'
import gImage from '@/components/v2/image.vue'
import defaultUserAvatar from '@/assets/img/profile_avatar_small.png'

import profileUsersRelationship from '@/views/v2/profile/profile_users_relationship.vue'

export default {
  components: {
    grid,
    gridColumn,
    list,
    listItem,
    placeholder,
    dropdown,
    dropdownItem,
    flex,
    gImage,
    profileUsersRelationship,
    loading,
  },
  props: {
    relationship: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['superior', 'subordinate'].includes(value),
    },
  },
  data() {
    return {
      pluralize,
      defaultUserAvatar,
      addingUsersRelationship: false,
      usersRelationships: [],
      usersRelationshipsPagination: null,
      relationshipLoading: false,
      patchingUserRelationshipId: null,
    }
  },
  computed: {
    roleName() {
      if (this.type === 'superior') {
        return this.relationship.superiorTitle || this.relationship.name
      }

      if (this.type === 'subordinate') {
        return this.relationship.subordinateTitle || this.relationship.name
      }

      throw new Error(`Unknown type ${this.type} for relationship`)
    },
  },
  mounted() {
    this.getSection()
  },
  methods: {
    async getSection() {
      try {
        this.relationshipLoading = true
        await this.getUsersRelationships()
      } catch (error) {
        toast.error(error)
      } finally {
        this.relationshipLoading = false
      }
    },
    async getUsersRelationships() {
      try {
        if (this.type === 'superior') {
          this.usersRelationships = (
            await api.get(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
              {
                relationshipId: this.relationship.id,
                secondUserId: this.user.id,
                userStatus: ['active', 'onboarding'],
              }
            )
          ).data
        }

        if (this.type === 'subordinate') {
          this.usersRelationships = (
            await api.get(
              `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
              {
                relationshipId: this.relationship.id,
                firstUserId: this.user.id,
                userStatus: ['active', 'onboarding'],
              }
            )
          ).data
        }
      } catch (error) {
        toast.error(error)
      }
    },
    async createUsersRelationship(id) {
      if (!id) {
        return
      }

      let payload = {}
      if (this.type === 'superior') {
        payload = {
          firstUserId: id,
          relationshipId: this.relationship.id,
          secondUserId: this.user.id,
        }
      }
      if (this.type === 'subordinate') {
        payload = {
          firstUserId: this.user.id,
          relationshipId: this.relationship.id,
          secondUserId: id,
        }
      }

      try {
        this.addingUsersRelationship = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/users-relationships`,
          payload
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.addingUsersRelationship = false
      }

      await this.getUsersRelationships()

      this.$refs.role.clear()
    },
  },
}
</script>
