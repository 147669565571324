<template>
  <grid stackable doubling>
    <grid-column>
      <h2>Custom Fields</h2>
      <g-table v-if="hasCustomFields" basic compact fixed>
        <table-body>
          <table-row
            v-for="userAspect in userAspects"
            :key="userAspect.id + refreshKey"
          >
            <table-cell>
              {{ userAspect.aspect.name }}
            </table-cell>
            <table-cell>
              <date-picker
                v-if="userAspect.aspect.type === 'date'"
                dropdown
                variant="isoDate"
                :date="parseISO(userAspect.value)"
                :loading="patchingUserAspect === userAspect.id"
                @update="(value) => patchUserAspect(userAspect.id, value)"
              />
              <editable-text
                v-if="userAspect.aspect.type === 'text'"
                :value="userAspect.value"
                :placeholder="userAspect.aspect.name"
                :loading="patchingUserAspect === userAspect.id"
                @update="(value) => patchUserAspect(userAspect.id, value)"
              />
            </table-cell>
            <table-cell right-aligned>
              <btn
                dismiss
                tooltip="Clear Information"
                :loading="deletingUserAspect === userAspect.id"
                compact
                size="mini"
                @click.native="() => showDeleteModal(userAspect.id)"
              />
            </table-cell>
          </table-row>
          <table-row v-for="aspect in aspects" :key="aspect.id">
            <table-cell>
              {{ aspect.name }}
            </table-cell>
            <table-cell>
              <date-picker
                v-if="aspect.type === 'date'"
                dropdown
                variant="isoDate"
                :loading="creatingUserAspect"
                @update="(value) => createUserAspect(aspect.id, value)"
              />
              <editable-text
                v-if="aspect.type === 'text'"
                :value="aspect.value"
                :loading="creatingUserAspect"
                :placeholder="aspect.name"
                @update="(value) => createUserAspect(aspect.id, value)"
              />
            </table-cell>
            <table-cell right-aligned />
          </table-row>
        </table-body>
      </g-table>
      <placeholder v-else light>
        {{ user.fullName }} has no custom fields
      </placeholder>
    </grid-column>
    <confirm-modal
      ref="delete"
      negative
      approve-text="Delete Information"
      deny-text="Cancel"
      size="mini"
      @approve="deleteUserAspect"
      @deny="() => (aspectIdForDeletion = null)"
    >
      Are you sure you want to delete this information?
    </confirm-modal>
  </grid>
</template>

<script>
import { parseISO } from 'date-fns'

import { api } from '@/api'
import { toast } from '@/toasts'

import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import datePicker from '@/components/v2/date_picker.vue'
import editableText from '@/components/v2/editable_text.vue'
import btn from '@/components/v2/btn.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import confirmModal from '@/components/v2/confirm_modal.vue'
import placeholder from '@/components/v2/placeholder.vue'

export default {
  components: {
    gTable,
    tableCell,
    tableBody,
    tableRow,
    datePicker,
    editableText,
    btn,
    grid,
    gridColumn,
    confirmModal,
    placeholder,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userAspects: {
      type: Array,
      default: () => [],
    },
    aspects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      parseISO,
      patchingUserAspect: null,
      deletingUserAspect: null,
      creatingUserAspect: false,
      aspectIdForDeletion: null,
      refreshKey: 0,
    }
  },
  computed: {
    hasCustomFields() {
      return !!(this.aspects.length || this.userAspects.length)
    },
  },
  methods: {
    async patchUserAspect(id, value) {
      if (!value) {
        this.refreshKey += 1
        return
      }
      try {
        this.patchingUserAspect = id
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects/${id}`,
          { value }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.patchingUserAspect = null
      }
      this.$emit('get-user-aspects')
      this.$emit('get-user')
    },
    async deleteUserAspect() {
      try {
        this.deletingUserAspect = this.aspectIdForDeletion
        await api.delete(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects/${this.aspectIdForDeletion}`
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.deletingUserAspect = null
        this.aspectIdForDeletion = null
      }

      this.$emit('get-user-aspects')
      this.$emit('get-aspects')
      this.$emit('get-user')
    },
    async createUserAspect(aspectId, value) {
      if (!value) {
        return
      }
      try {
        this.creatingUserAspect = true
        await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/user-aspects`, {
          userId: this.user.id,
          aspectId,
          value,
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingUserAspect = false
      }

      this.$emit('get-user-aspects')
      this.$emit('get-aspects')
      this.$emit('get-user')
    },
    showDeleteModal(aspectId) {
      this.aspectIdForDeletion = aspectId
      this.$refs.delete.show()
    },
  },
}
</script>
