<template>
  <grid stackable doubling>
    <grid-column>
      <flex justify="space-between" align="flex-start">
        <h2>Moments</h2>
        <dropdown
          name="moments"
          button
          basic
          compact
          button-variant="primary"
          default-text="Add"
          hide-caret
          scrolling
          hide-selection
          :loading="creatingEnrollee"
          :disabled="moments.length === 0"
          @update="(value) => createEnrollee(value)"
          @search="(value) => $emit('get-moments', value)"
        >
          <dropdown-search />
          <dropdown-item
            v-for="moment in moments"
            :key="moment.id"
            :value="moment.id"
          >
            <flex align="center" gap="1em">
              <moment-icon
                :hex-color="moment.color"
                :icon="moment.icon"
                size="small"
              />
              {{ moment.name }}
            </flex>
          </dropdown-item>
        </dropdown>
      </flex>
      <list middle-aligned selection>
        <list-item
          v-for="moment in enrolledMoments"
          :key="moment.id"
          :to="{ name: 'moment', params: { id: moment.id } }"
        >
          <profile-moment
            :moment="moment"
            :user="user"
            @get-moments="() => $emit('get-moments')"
          />
        </list-item>

        <placeholder v-if="enrolledMoments.length === 0" light>
          {{ user.fullName }} is not enrolled in any moments right now.
        </placeholder>
      </list>
    </grid-column>
  </grid>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import placeholder from '@/components/v2/placeholder.vue'
import dropdown from '@/components/v2/dropdown/dropdown.vue'
import dropdownSearch from '@/components/v2/dropdown/search.vue'
import dropdownItem from '@/components/v2/dropdown/item.vue'
import flex from '@/components/v2/flex.vue'
import momentIcon from '@/components/v2/moment_icon.vue'

import profileMoment from '@/views/v2/profile/profile_moment.vue'

export default {
  components: {
    grid,
    gridColumn,
    list,
    listItem,
    placeholder,
    dropdown,
    dropdownSearch,
    dropdownItem,
    flex,
    momentIcon,
    profileMoment,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    moments: {
      type: Array,
      default: () => [],
    },
    enrolledMoments: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creatingEnrollee: false,
    }
  },
  methods: {
    async createEnrollee(momentId) {
      try {
        this.creatingEnrollee = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/moments/${momentId}/enrollees/individuals`,
          {
            userId: this.user.id,
          }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.creatingEnrollee = false
      }
      this.$emit('get-moments')
    },
  },
}
</script>
